import React, { useContext, useState, createContext } from "react";

const WarningContext = createContext<{
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    preventDefaultNavigation: (e: React.MouseEvent<HTMLElement>, targetHref: string) => void;
}>({
    isOpen: true,
    onConfirm: () => {},
    onCancel: () => {},
    preventDefaultNavigation: () => {},
});

export const useAgeWarningState = () => useContext(WarningContext);

export const WarningContextProvider = (props: {
    children: React.ReactNode;
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [href, setHref] = useState<string>('');

    const onCancel = () => {
        setIsOpen(false);
    };

    const onConfirm = () => {
        // window.open will behave differently depending on the browser's settings,
        // so using an anchor object will align with the same behavior as the original anchor.
        const anchor = document.createElement('a');
        anchor.setAttribute('href', href);
        anchor.setAttribute('target', '_blank');
        anchor.setAttribute('rel', 'noopener noreferrer');
        anchor.click();

        onCancel();
    };

    const preventDefaultNavigation = (e: React.MouseEvent<HTMLElement>, targetHref: string) => {
        e.preventDefault();
        setHref(targetHref);
        setIsOpen(true);
    };

    return (
        <WarningContext.Provider value={{ isOpen, onConfirm, onCancel, preventDefaultNavigation }}>
            {props.children}
        </WarningContext.Provider>
    );
};
