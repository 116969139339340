import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export type Props = {
    onConfirm: () => void;
    onCancel: () => void;
    open: boolean;
    title: string;
    confirm: string;
    cancel: string;
    children?: React.ReactNode;
    description: React.ReactNode;
    icon: React.ReactNode;
    type?: string;
};

export function Warning(props: Props) {
    const { t } = useTranslation();
    const preventClose = () => {};

    return (
        <Transition appear show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={preventClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/[0.49]" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center bg-[#333] lg:bg-transparent">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-screen h-full max-w-md lg:max-w-lg transform overflow-hidden p-6 align-middle font-bold lg:rounded-[36px] lg:bg-[#171717]">
                                <div className="space-y-4">
                                    <div className="text-4xl text-pink space-y-4">
                                        {props.icon}
                                        <div>
                                            {props.type ?? t("modal.warning")}
                                        </div>
                                    </div>

                                    <Dialog.Description
                                        as="div"
                                        className="text-sm lg:text-xl text-white leading-relaxed lg:leading-relaxed"
                                    >
                                        {props.description}
                                    </Dialog.Description>

                                    <Dialog.Title className="text-2xl lg:text-4xl text-[#8FC5E4]">
                                        {props.title}
                                    </Dialog.Title>
                                </div>

                                <div className="grid lg:grid-cols-2 gap-4 mt-10 max-w-md mx-auto">
                                    <button
                                        type="button"
                                        className="rounded bg-[#8FC5E4] px-4 py-2 lg:py-3 text-xl lg:text-2xl focus:outline-none"
                                        onClick={props.onConfirm}
                                    >
                                        {props.confirm}
                                    </button>
                                    <button
                                        type="button"
                                        className="rounded bg-brown-100 px-4 py-2 lg:py-3 text-xl lg:text-2xl focus:outline-none"
                                        onClick={props.onCancel}
                                    >
                                        {props.cancel}
                                    </button>
                                    {props.children}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
